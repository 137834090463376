import React, { useState, useEffect } from "react";
import "./styles.css";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MENU_PDF = "/files/menu-2.pdf";

function Menu() {
  const mobile = window.innerWidth < 800;
  const width = mobile ? window.innerWidth : 800;
  const [pagesCount, setPagesCount] = useState(null);
  const [visible, setVisible] = useState(false);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setPagesCount(numPages);
  };

  const backdropClick = e => {
    console.log(e.target.tagName);
    if (e.target.tagName.toLowerCase() === "canvas") {
      return;
    }
    setVisible(false);
  };

  const items = [];
  for (let index = 1; index <= pagesCount; index++) {
    items.push(<Page key={index} pageNumber={index} width={width} />);
  }

  return (
    <div className="Menu">
      <button className="menu-btn" onClick={() => setVisible(true)}>
        Speisekarte öffnen
      </button>
      <div
        className={"menu-overlay" + (visible ? " visible" : "")}
        onClick={backdropClick}
      >
        {mobile ? (
          <button
            className="menu-overlay-close-btn"
            onClick={() => setVisible(false)}
          >
            schließen
          </button>
        ) : null}
        <Document
          file={MENU_PDF}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {items}
        </Document>
      </div>
    </div>
  );
}

export default Menu;
