import React, { useState, useEffect } from "react";
import "./styles.css";

function Slider({ images }) {
  const [intId, setIntId] = useState();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIntId(
      setInterval(() => {
        setIndex(oldIndex => {
          return (oldIndex + 1) % images.length;
        });
      }, 3000)
    );
  }, []);

  return (
    <div className="Slider">
      {images.map((image, i) => (
        <div
          key={image}
          style={{ backgroundImage: `url(${image})` }}
          className={"slider-item" + (i === index ? " selected" : "")}
        ></div>
      ))}
      <div className="slider-overlay"></div>
    </div>
  );
}

export default Slider;
