import React from "react";
import "./styles.css";

function Header({ logo }) {
  return (
    <div className="Header">
      <div className="band">
        <div
          style={{ backgroundImage: `url(${logo})` }}
          className="logo"
        ></div>
      </div> 
    </div>
  );
}

export default Header;
