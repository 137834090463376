import React from 'react';
import Slider from './components/Slider';
import Header from './components/Header';
import Menu from './components/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'
import './App.css';

const sliderImages = [
  "images/image0.JPEG",
  "images/image1.JPEG",
  "images/image2.JPG",
  "images/image3.JPG",
  "images/image4.JPG",
  "images/image5.JPEG",
  "images/image6.jpg"
];

function App() {
  return (
    <React.Fragment>
      <div className="background-stripe"></div>
      <div className="App">
        <Slider images={sliderImages} />
        <Header logo="/images/logo.png" />
        <div className="main">
          <h1>Benvenuti La Perla del Lago</h1>
          <ul className="social">
            <li>
              <a target="_blank" href="https://www.instagram.com/la.perla.del.lago">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.facebook.com/pages/category/Beer-Garden/La-Perla-Del-Lago-342110353355175/">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
          </ul>
          <p>
            Für ein festliches Essen & schöne Stunden mit Ihrer Familie bei uns im Restaurant bieten wir Ihnen Reservierungsmöglichkeiten für Ihre Feiern und Feste.
            <br/>Wir Würden uns über Ihre Anfrage freuen.
            <br/><br/>Erreichbar unter der Telefonnummer: <a href="tel:+4981529893131">+49 8152 9893131</a>
          </p>

          <Menu />

          <div className="bottom">
            <div>
              <h2>Kontakt:</h2>
              <b>La Perla del Lago</b><br />
              Hauptstraße 6<br />
              82211 Breitbrunnn
            </div>
            <div>
            <h2>Öffnungszeiten:</h2>

              <div className="hor-stack">
                <div>
                  <b>Mo-Di:</b><br/>
                  11:30 - 14:30<br/>
                  17:00 - 22:00
                </div>
                
                <div>
                  <b>Mittwoch:</b><br/>Ruhetag
                </div>

                <div>
                  <b>Do-So</b>:<br/>
                  11:30 - 14:30<br/>
                  17:00 - 22:00
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
